import React from "react";
// import { Link, Trans } from 'gatsby-plugin-react-i18next';
import Trans from '../components/Trans'
import { Link } from "gatsby";

const FreeResources = () => {
    
    return (
        <section className="px-6 py-20 pb-0 md:py-40 md:pb-0">
            <div className="container max-w-6xl mx-auto">
                <div className="text-center">
                    <div className="section-label"><Trans>Free Resources</Trans></div>
                    <h2 className="section-title">
                        <Trans>Our Resource Library Is Absolutely Free of Cost!</Trans>
                    </h2>
                    <p className="section-subtitle">
                        <Trans>Keep yourself updated with our latest HR blogs, webinars, guides and ebooks, podcasts, and more.</Trans>
                    </p>
                </div>
                <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 text-center">
                    <div className="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg">
                        <div className="bg-white p-6 h-full">
                            <div className="relative px-4 py-6">
                                <svg className="svgicon mx-auto" x="0px" y="0px" viewBox="0 0 512 512">
                                    <g className="st0">
                                        <path className="st1" d="M121,503.2V392H8.8L121,503.2z"/>
                                        <path className="st1" d="M221.6,221.6l21.2,63.6l10.6,10.6l42.4-42.4l-10.6-10.6L221.6,221.6z"/>
                                        <path className="st1" d="M274.6,317.1l42.4-42.4l141.9,141.9L416.5,459L274.6,317.1z"/>
                                        <path className="st1" d="M437.7,480.2l42.4-42.4l23,23c4.9,4.9,4.9,12.7,0,17.6l-24.8,24.8c-4.9,4.9-12.7,4.9-17.6,0L437.7,480.2z"/>
                                        <path className="st1" d="M391,306.2V14.6c0-8-6.5-14.6-14.6-14.6H14.6C6.5,0,0,6.5,0,14.6V362h151v150h225.4c8,0,14.6-6.5,14.6-14.6
                                            v-21.6L216.6,301.5l-42.4-127.3l127.3,42.5L391,306.2z M121,46h210v30H121V46z M61,46h30v30H61V46z M61,136v-30h270v30H61z"/>
                                    </g>
                                </svg>
                            </div>
                            <h3 className="box-title"><Trans>Blog</Trans></h3>
                            <p className="box-subtitle md:h-20"><Trans>Insightful articles, best practices, and trends in HR innovation.</Trans></p>
                            <div className="mt-5 w-full">
                                <div className="relative -bottom-15">
                                    <a href="https://www.vantagecircle.com/blog/" className="vc-ghost-btn group-hover:bg-purple-300 group-hover:text-white">
                                        <Trans>Read Now</Trans>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg">
                        <div className="bg-white p-6 h-full">
                            <div className="relative px-4 py-6">
                                <svg className="svgicon mx-auto" x="0px" y="0px" viewBox="0 0 512 512">
                                    <g className="st0">
                                        <g>
                                            <path className="st1" d="M370.2,67.6h-25.6V15.4c-0.3-8.4-7-15.1-15.4-15.4H86.5C59.4,0,37.4,22,37.4,49.2v324.6
                                                c0,27.1,22,49.2,49.2,49.2h283.6c8.5,0,15.4-6.9,15.4-15.4V82.9C385.5,74.5,378.7,67.6,370.2,67.6z M215.6,230.9l-36.9-30.2
                                                c-5-3-11.3-3-16.4,0l-36.9,30.2V98.3h90.1V230.9z M313.9,67.6H86.5c-10.2,0-18.4-8.3-18.4-18.4s8.3-18.4,18.4-18.4h227.3
                                                L313.9,67.6L313.9,67.6z"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <h3 className="box-title"><Trans>Free eBooks</Trans></h3>
                            <p className="box-subtitle md:h-20"><Trans>A free resources center with practical guides on HR management.</Trans></p>
                            <div className="mt-5 w-full">
                                <div className="relative -bottom-15">
                                    <a href="/resources/" className="vc-ghost-btn group-hover:bg-purple-300 group-hover:text-white">
                                        <Trans>Download Now</Trans>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg">
                        <div className="bg-white p-6 h-full">
                            <div className="relative px-4 py-6">
                                <svg className="svgicon mx-auto" x="0px" y="0px" viewBox="0 0 512 512">
                                    <g className="st0">
                                        <g>
                                            <path className="st1" d="M439.5,209.3c-5.7,0-10.6,2.1-14.7,6.2c-4.1,4.1-6.2,9.1-6.2,14.7v41.9c0,40.3-14.3,74.8-43,103.5
                                                c-28.7,28.7-63.2,43-103.5,43c-40.3,0-74.8-14.3-103.5-43c-28.7-28.7-43-63.2-43-103.5v-41.9c0-5.7-2.1-10.6-6.2-14.7
                                                c-4.1-4.1-9.1-6.2-14.7-6.2c-5.7,0-10.6,2.1-14.7,6.2c-4.1,4.1-6.2,9.1-6.2,14.7v41.9c0,48.2,16.1,90.1,48.2,125.7
                                                c32.2,35.7,71.9,56.1,119.2,61.3v43.2h-83.7c-5.7,0-10.6,2.1-14.7,6.2c-4.1,4.1-6.2,9-6.2,14.7c0,5.7,2.1,10.6,6.2,14.7
                                                c4.1,4.1,9,6.2,14.7,6.2h209.3c5.7,0,10.6-2.1,14.7-6.2c4.1-4.1,6.2-9.1,6.2-14.7c0-5.7-2.1-10.6-6.2-14.7
                                                c-4.1-4.1-9.1-6.2-14.7-6.2H293v-43.2c47.3-5.2,87-25.7,119.2-61.3c32.2-35.6,48.2-77.6,48.2-125.7v-41.9c0-5.7-2.1-10.6-6.2-14.7
                                                C450.1,211.4,445.2,209.3,439.5,209.3z"/>
                                            <path className="st1" d="M222.8,203.7h-55.3v38.4h55.3c8.5,0,15.3,6.8,15.3,15.3s-6.8,15.3-15.3,15.3h-55.3
                                                c0.2,28.5,10.4,52.9,30.7,73.3c20.5,20.5,45.1,30.7,73.9,30.7c28.8,0,53.4-10.2,73.9-30.7c20.3-20.3,30.6-44.8,30.7-73.3h-53.4
                                                c-8.5,0-15.3-6.8-15.3-15.3s6.8-15.3,15.3-15.3h53.4v-38.4h-53.4c-8.5,0-15.3-6.8-15.3-15.3s6.8-15.3,15.3-15.3h53.4v-40.8h-53.4
                                                c-8.5,0-15.3-6.8-15.3-15.3s6.8-15.3,15.3-15.3h53.3c-0.7-27.5-10.8-51.2-30.6-71C325.5,10.2,300.9,0,272.1,0
                                                c-28.8,0-53.4,10.2-73.9,30.7c-19.8,19.8-29.9,43.5-30.6,71h55.2c8.5,0,15.3,6.8,15.3,15.3s-6.8,15.3-15.3,15.3h-55.3v40.8h55.3
                                                c8.5,0,15.3,6.8,15.3,15.3S231.2,203.7,222.8,203.7z"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <h3 className="box-title"><Trans>Influencers Podcast</Trans></h3>
                            <p className="box-subtitle md:h-20"><Trans>Listen to thought leaders on best HR practices and trends.</Trans></p>
                            <div className="mt-5 w-full">
                                <div className="relative -bottom-15">
                                    <a href="https://www.vantagecircle.com/blog/podcasts/" className="vc-ghost-btn group-hover:bg-purple-300 group-hover:text-white">
                                        <Trans>Listen Now</Trans>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="group border border-transparent hover:border-purple-300 card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg">
                        <div className="bg-white p-6 h-full">
                            <div className="relative px-4 py-6">
                                <svg className="svgicon mx-auto" x="0px" y="0px" viewBox="0 0 512 512">
                                    <path className="st3" d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M344.5,269.6l-128,80
                                    c-2.6,1.6-5.5,2.4-8.5,2.4c-2.7,0-5.3-0.7-7.8-2c-5.1-2.8-8.2-8.2-8.2-14V176c0-5.8,3.2-11.2,8.2-14c5.1-2.8,11.3-2.7,16.2,0.4
                                    l128,80c4.7,2.9,7.5,8.1,7.5,13.6S349.2,266.6,344.5,269.6z"/>
                                </svg>
                            </div>
                            <h3 className="box-title">Doers <Trans>Webinar</Trans></h3>
                            <p className="box-subtitle md:h-20"><Trans>Watch our webinar series to get practical lessons on employee engagement strategies.</Trans></p>
                            <div className="mt-5 w-full">
                                <div className="relative -bottom-15">
                                    <Link to="/webinars/" className="vc-ghost-btn group-hover:bg-purple-300 group-hover:text-white">
                                        <Trans>Watch Now</Trans>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default FreeResources;